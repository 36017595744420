import React from 'react';

import './styles.scss';

const Loading = ({loading, menu}) => {
    return (
        loading ?
            <div className={menu ? 'spinner-group unpined' : 'pinned spinner-group'}>
                
                <div className="spinner-grow spinner spinner1" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
                <div className="spinner-grow spinner spinner2" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
                <div className="spinner-grow spinner spinner3" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        : null
    )
}

export default Loading;